import { NgIf } from '@angular/common';
import { Directive, inject, Input } from '@angular/core';
import { LocalizedString, LocalizeService } from '@examdojo/core/i18n';

@Directive({
  selector: '[dojoIfLocalizedString]',
  standalone: true,
})
export class IfLocalizedStringDirective extends NgIf<string> {
  private readonly localizeService = inject(LocalizeService);

  @Input('dojoIfLocalizedString') set stringOrLocalizedString(stringOrLocalizedString: string | LocalizedString) {
    const resolvedString =
      typeof stringOrLocalizedString === 'string'
        ? stringOrLocalizedString
        : this.localizeService.localizeOnce(stringOrLocalizedString);

    this.ngIf = resolvedString;
  }
}
