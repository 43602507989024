import { NgIf } from '@angular/common';
import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UntilDestroy } from '@ngneat/until-destroy';
import { connectState } from '@examdojo/angular/util';
import { Icon, IconModule } from '@examdojo/core/icon';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';
import { BehaviorSubject } from 'rxjs';

type SortKey = null | 'asc' | 'desc';

export interface TextHeaderRendererComponentParams {
  icon?: Icon;
  tooltip?: string;
}

interface TextHeaderRendererParams extends IHeaderParams, TextHeaderRendererComponentParams {}

@UntilDestroy()
@Component({
  imports: [NgIf, IconModule, MatTooltipModule],
  standalone: true,
  selector: 'y42-text-header-renderer',
  templateUrl: './text-header-renderer.component.html',
  styleUrls: ['./text-header-renderer.component.scss'],
})
export class TextHeaderRendererComponent implements IHeaderAngularComp, OnDestroy {
  params!: TextHeaderRendererParams;

  @ViewChild('menuButton', { read: ElementRef }) public menuButton?: ElementRef;

  private readonly filterActive$$ = new BehaviorSubject<boolean>(false);

  sorted: SortKey = null;

  public readonly state = connectState({
    filterActive: this.filterActive$$,
  });

  agInit(params: TextHeaderRendererParams): void {
    this.params = params;
    this.params.column.addEventListener('sortChanged', this.sortChange);
    this.params.column.addEventListener('filterChanged', this.changeFilter);
    this.filterActive$$.next(this.params.column.isFilterActive());
  }

  ngOnDestroy(): void {
    this.params.column.removeEventListener('sortChanged', this.sortChange);
    this.params.column.addEventListener('filterChanged', this.changeFilter);
  }

  refresh(params: TextHeaderRendererParams): boolean {
    this.params = params;
    return true;
  }

  readonly sortChange = () => {
    this.sorted = null;
    if (this.params.column.isSortAscending()) {
      this.sorted = 'asc';
    } else if (this.params.column.isSortDescending()) {
      this.sorted = 'desc';
    }
  };

  showSorted() {
    this.params.setSort(this.sortSwitch(this.sorted));
  }

  sortSwitch(sort: SortKey) {
    if (sort === null) {
      return 'asc';
    } else if (sort === 'asc') {
      return 'desc';
    } else {
      return null;
    }
  }

  showColumnMenu() {
    if (this.menuButton) {
      this.params.showColumnMenu(this.menuButton.nativeElement);
    }
  }

  readonly changeFilter = () => {
    this.filterActive$$.next(this.params.column.isFilterActive());
  };
}
