import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { connectState, InputObservable } from '@examdojo/angular/util';
import { AnimationDuration, getAnimationAppearDisappear } from '@examdojo/animation';
import { Icon, IconComponent } from '@examdojo/core/icon';
import { UntilDestroy } from '@ngneat/until-destroy';
import { map, Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'dojo-empty-state',
  standalone: true,
  imports: [MatButtonModule, IconComponent, NgIf, NgClass, NgTemplateOutlet],
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [getAnimationAppearDisappear({ leave: AnimationDuration.None })],
})
export class EmptyStateComponent {
  @Input() header?: string;
  @Input() iconName?: Icon;
  @Input() isIconBig?: boolean;
  @Input() body?: string;
  @Input() iconColor?: string = 'text-gray-400';
  @Input() image?: string;

  @Input() @InputObservable() addButtonLabel?: string | TemplateRef<unknown>;
  private readonly addButtonLabel$!: Observable<string | TemplateRef<unknown> | undefined>;

  @Output() add = new EventEmitter<void>();

  @HostBinding('class.empty-state-wrapper') @Input() hasBackgroundAndBorder?: boolean;
  @HostBinding('class.empty-state-light-wrapper') @Input() hasLightBackgroundAndBorder?: boolean;
  @HostBinding('@appearDisappear') animate = true;

  readonly state = connectState({
    addButtonLabelTemplate: this.addButtonLabel$.pipe(
      map((label) => (label instanceof TemplateRef ? label : undefined)),
    ),
  });
}
