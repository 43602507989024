import { EntityState } from '@examdojo/state';
import { Enum, TableInsertModel, TableRowModel, TableUpdateModel } from '@examdojo/supabase';
import { UserStorage } from './user-storage.model';

export type UserHttpModel = Pick<
  TableRowModel<'users'>,
  | 'course_id'
  | 'syllabus_id'
  | 'course_level'
  | 'email'
  | 'final_exam_date'
  | 'first_name'
  | 'id'
  | 'last_name'
  | 'onboarded_app'
  | 'onboarded_topic_practice'
  | 'origin'
  | 'profile_pic'
  | 'school_name'
  | 'updated_at'
  | 'storage'
  | 'streak_goal'
  | 'current_onboarding_step'
>;

export type OnboardingStep = Enum<'onboarding_step'>;

export type UserStoreModel = Omit<UserHttpModel, 'storage' | 'course_id'> & {
  storage: UserStorage | null;
  /**
   * @deprecated Use syllabus_id and course_level instead
   */
  course_id: UserHttpModel['course_id'];
};

export type UserUIModel = UserStoreModel & {
  fullName: string;
};

export interface UserState extends EntityState<UserStoreModel> {}

export const USER_CREATE_ALLOWED_KEYS = [
  'email',
  'first_name',
  'last_name',
  'profile_pic',
  'course_id',
  'final_exam_date',
  'school_name',
  'onboarded_topic_practice',
  'onboarded_app',
  'origin',
  'syllabus_id',
  'course_level',
  'streak_goal',
  'current_onboarding_step',
] satisfies Array<keyof TableInsertModel<'users'>>;

export type UserCreateModel = Pick<TableInsertModel<'users'>, (typeof USER_CREATE_ALLOWED_KEYS)[number]>;

export const USER_UPDATE_ALLOWED_KEYS = [...USER_CREATE_ALLOWED_KEYS] satisfies Array<keyof TableUpdateModel<'users'>>;

export type UserUpdateModel = Pick<TableUpdateModel<'users'>, (typeof USER_UPDATE_ALLOWED_KEYS)[number]>;
