import { getTrackByFn } from '@examdojo/angular/util';
import { UnwrapArray } from '@examdojo/core/typescript';
import { assertNonNullable } from '@examdojo/util/assert';
import { isNotNullish } from '@examdojo/util/nullish';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { BehaviorSubject, filter, map, Observable } from 'rxjs';

export type SingleParamValue<TValue = unknown> = TValue;
export type ArrayParamValue<TValue = unknown> = Array<{ type: TValue; count: number }>;
export type ParamValue<TValue = unknown> = SingleParamValue<TValue> | ArrayParamValue<TValue> | undefined;
export type Params<
  TValue,
  TData = unknown,
  CellRendererParams extends object = ICellRendererParams<TData, TValue>,
> = ICellRendererParams<TData, TValue> & CellRendererParams;

export class BaseCellRendererComponent<
  TValue,
  TData = unknown,
  CellRendererParams extends ICellRendererParams<TData, TValue> = ICellRendererParams<TData, TValue>,
> implements ICellRendererAngularComp
{
  readonly trackByType = getTrackByFn<UnwrapArray<ArrayParamValue<TValue>>>('type');

  private readonly params$$ = new BehaviorSubject<CellRendererParams | undefined>(undefined);

  protected readonly value$: Observable<ParamValue<TValue>> = this.params$$.pipe(map((param) => param?.value));
  protected readonly params$ = this.params$$.asObservable().pipe(filter(isNotNullish));
  protected readonly data$: Observable<TData | undefined> = this.params$$.pipe(map((param) => param?.data));
  protected readonly valueAsArray$ = this.value$.pipe(
    map((value): ArrayParamValue<TValue> | undefined => (Array.isArray(value) ? value : undefined)),
  );
  protected readonly valueAsSingle$ = this.value$.pipe(
    map((value): SingleParamValue<TValue> | undefined => (!Array.isArray(value) ? value : undefined)),
  );

  get params() {
    return this.params$$.value;
  }

  agInit(params: CellRendererParams): void {
    this.setCellRendererParams(params);
  }

  refresh(params: CellRendererParams): boolean {
    this.setCellRendererParams(params);
    return true;
  }

  protected patchCellRendererParams(params: Partial<CellRendererParams>): void {
    assertNonNullable(this.params, 'this.params');
    this.setCellRendererParams({ ...this.params, ...params });
  }

  private setCellRendererParams(params: CellRendererParams): void {
    this.params$$.next(params);
  }
}
