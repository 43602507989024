import { NgModule } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { AgGridModule } from 'ag-grid-angular';
import { AgGridDirective } from './ag-grid.directive';

@NgModule({
  imports: [AgGridModule],
  exports: [AgGridDirective, AgGridModule],
  declarations: [AgGridDirective],
})
export class ExamdojoAgGridModule {}
