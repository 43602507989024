import { Pipe, PipeTransform } from '@angular/core';
import { ExtendedUnitOfTime } from './date-time.model';
import { DateTimeService } from './date-time.service';

@Pipe({
  name: 'formatDuration',
})
export class FormatDurationPipe implements PipeTransform {
  constructor(private readonly dateTimeService: DateTimeService) {}
  transform(amountOfTime: number, unit: ExtendedUnitOfTime = 'milliseconds'): string {
    return this.dateTimeService.formatDuration(amountOfTime, { unit });
  }
}
