import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[dojoStopPropagation]',
  standalone: true,
})
export class StopPropagationDirective {
  // Allow using the [y42StopPropagation] syntax
  // to force Angular compiler to check if the directive is included.
  @Input() dojoStopPropagation?: '';

  @HostListener('click', ['$event'])
  onClick(e: MouseEvent) {
    e.stopPropagation();
  }
}
