import { keys } from './object-utils';

export const MIN_FRACTION_DEFAULT = 0;
export const MAX_FRACTION_DEFAULT = 0;

export type FormatBy = 'Automatic' | 'B' | 'M' | 'K' | null;

export function formatValueConversion(
  value: number,
  locale: string,
  formatBy: FormatBy = null,
  minFraction = MIN_FRACTION_DEFAULT,
  maxFraction = MAX_FRACTION_DEFAULT,
): string {
  type Suffix = Exclude<FormatBy, 'Automatic' | null>;
  const dividerMap: Record<Suffix, number> = {
    ['B']: 1.0e9,
    ['M']: 1.0e6,
    ['K']: 1.0e3,
  };

  if (formatBy === 'Automatic') {
    // if user passed "Automatic" - wants us to choose the best "Suffix" possible
    const findInDescOrder = () => {
      const suffixesInDescOrder: Suffix[] = keys(dividerMap)
        .map((key) => ({ key, val: dividerMap[key] }))
        .sort((a, b) => b.val - a.val)
        .map(({ key }) => key); // ['B', 'M', 'K'];

      let found: Suffix | null = null;
      for (const suffix of suffixesInDescOrder) {
        if (Math.abs(value) / dividerMap[suffix] >= 1) {
          found = suffix;
          break;
        }
      }
      return found;
    };

    formatBy = findInDescOrder();
  }

  const divider = formatBy === null ? 1 : dividerMap?.[formatBy];

  if (!divider) {
    return 'n/a';
  }

  const fractions = {
    minimumFractionDigits: minFraction,
    maximumFractionDigits: maxFraction,
  };

  const suffix = formatBy ? formatBy : '';
  return `${(Number(value) / divider).toLocaleString(locale, fractions)}${suffix}`;
}
