import { NgFor } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { UntilDestroy } from '@ngneat/until-destroy';
import { connectState, getTrackByFn } from '@examdojo/angular/util';
import { IconComponent } from '@examdojo/core/icon';
import { StopPropagationModule } from '@examdojo/core/stop-propagation';
import { ColDef, IRowNode } from 'ag-grid-community';
import { BehaviorSubject, combineLatest, map, Observable, of, startWith } from 'rxjs';
import { BaseCellRendererComponent, Params } from '../base-cell-renderer.component';
import { MenuOptionComponent, NormalizedMenuOption } from './menu-option/menu-option.component';

export type MenuOption = Omit<NormalizedMenuOption, 'classes'>;

type MenuOptionStringField<T> = string | Observable<string> | ((node: IRowNode<T>) => string | Observable<string>);

export interface MenuCellRendererParams<T> {
  triggerIconClass?: MenuOptionStringField<T>;
  options?: (node: IRowNode<T>) => MenuOption[];
  onEditItem: (node: IRowNode<T>) => void;
  editItemLabel?: MenuOptionStringField<T>;
  onDeleteItem: (node: IRowNode<T>) => void;
  deleteItemLabel?: MenuOptionStringField<T>;
}

type MenuCellRendererValue = unknown;
type MenuCellRendererData = unknown;
type ParamsType = Params<MenuCellRendererValue, MenuCellRendererData, MenuCellRendererParams<MenuCellRendererData>>;

@UntilDestroy()
@Component({
  selector: 'y42-menu-cell-renderer',
  templateUrl: './menu-cell-renderer.component.html',
  styleUrls: ['./menu-cell-renderer.component.scss'],
  standalone: true,
  imports: [NgFor, IconComponent, MatMenuModule, MatButtonModule, StopPropagationModule, MenuOptionComponent],
})
export class MenuCellRendererComponent extends BaseCellRendererComponent<
  MenuCellRendererValue,
  MenuCellRendererData,
  ParamsType
> {
  readonly trackByLabel = getTrackByFn<NormalizedMenuOption>('label');

  private readonly menuOpened$$ = new BehaviorSubject(false);

  readonly state = connectState({
    params: this.params$,
    options: combineLatest([this.params$, this.menuOpened$$]).pipe(
      map(([params, menuOpened]) => (menuOpened ? this.getOptions(params) : [])),
      startWith([] as NormalizedMenuOption[]),
    ),
    triggerIconClass: this.params$.pipe(map((params) => this.normalizeStringField(params, params.triggerIconClass))),
  });

  menuOpened() {
    this.menuOpened$$.next(true);
  }

  private getOptions(params: ParamsType): NormalizedMenuOption[] {
    const editItemOption: NormalizedMenuOption = {
      label: this.normalizeStringField(params, params.editItemLabel, 'Edit'),
      action: () => {
        params.onEditItem(params.node);
      },
      classes: of(''),
    };

    const deleteItemOption: NormalizedMenuOption = {
      label: this.normalizeStringField(params, params.deleteItemLabel, 'Delete'),
      icon: 'trash-alt',
      action: () => {
        params.onDeleteItem(params.node);
      },
      classes: of('text-typography-danger-primary'),
    };

    const options: NormalizedMenuOption[] = (params.options?.(params.node) || []).map((option) =>
      this.normalizeOption(option),
    );

    return [editItemOption, ...options, deleteItemOption];
  }

  private normalizeOption(option: MenuOption, classes = ''): NormalizedMenuOption {
    return {
      ...option,
      classes: of(classes),
    };
  }

  private normalizeStringField(
    params: ParamsType,
    label: MenuOptionStringField<unknown> | undefined,
    defaultValue?: string,
  ) {
    return typeof label === 'function' ? label(params.node) : label || defaultValue || '';
  }
}

export const MENU_CELL_RENDERER_BASE_COL_DEF = {
  colId: 'menu',
  headerName: '',
  width: 40,
  suppressAutoSize: true,
  suppressSizeToFit: true,
  resizable: false,
  sortable: false,
  suppressMenu: true,
  cellRenderer: MenuCellRendererComponent,
} satisfies ColDef;
