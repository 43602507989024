import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InputObservable, connectState } from '@examdojo/angular/util';
import { shareOneReplay } from '@examdojo/rxjs/share-one-replay';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable, combineLatest, map, of, switchMap, timer } from 'rxjs';
import { DateTimeFormat } from '../date-time.model';
import { DateTimeService } from '../date-time.service';

const ONE_MINUTE = 60_000;

interface RelativeConfig {
  showIfDaysDiff: number;
  format: DateTimeFormat;
}

const DEFAULT_RELATIVE_CONFIG: RelativeConfig = {
  showIfDaysDiff: 1,
  format: DateTimeFormat.ShortDateTime,
};

@UntilDestroy()
@Component({
  selector: 'dojo-time-ago',
  standalone: true,
  imports: [MatTooltipModule],
  templateUrl: './time-ago.component.html',
  styleUrls: ['./time-ago.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeAgoComponent {
  constructor(private readonly dateTimeService: DateTimeService) {}

  @Input({ required: true })
  @InputObservable()
  date: Date | string | undefined;
  date$!: Observable<Date | string | undefined>;

  @Input()
  @InputObservable()
  relativeConfig: Partial<RelativeConfig> | undefined;
  relativeConfig$!: Observable<Partial<RelativeConfig> | undefined>;

  @Input() showTooltip = false;

  private readonly dateWithConfig$ = combineLatest([
    this.date$,
    this.relativeConfig$.pipe(
      map((relativeConfig) => ({
        ...DEFAULT_RELATIVE_CONFIG,
        ...relativeConfig,
      })),
    ),
  ]).pipe(shareOneReplay());

  public readonly state = connectState({
    timeAgo: this.dateWithConfig$.pipe(
      switchMap(([date, { showIfDaysDiff, format }]) => {
        if (!date) {
          return of('');
        }

        return this.formatTimeAgo(typeof date === 'string' ? new Date(date) : date, showIfDaysDiff, format);
      }),
    ),
    tooltipDate: this.dateWithConfig$.pipe(
      map(([date, { format }]) => {
        if (!date) {
          return '';
        }

        return this.formatAbsolute(typeof date === 'string' ? new Date(date) : date, format);
      }),
    ),
  });

  private formatTimeAgo(timeAgo: Date, showRelativeIfDaysDiff: number, nonRelativeFormat: DateTimeFormat) {
    const showRelative = this.dateTimeService.diff(timeAgo, new Date(), 'days') < showRelativeIfDaysDiff;

    if (showRelative) {
      return timer(0, ONE_MINUTE).pipe(map(() => this.dateTimeService.formatDateRelativeToNow(timeAgo)));
    }

    return of(this.formatAbsolute(timeAgo, nonRelativeFormat));
  }

  private formatAbsolute(datetime: Date, nonRelativeFormat: DateTimeFormat) {
    return this.dateTimeService.formatDate(datetime, nonRelativeFormat);
  }
}
