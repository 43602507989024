import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@examdojo/core/environment';
import escapeRegExp from 'lodash/escapeRegExp';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ExamdojoAuthService } from './abstract-auth.service';

@Injectable()
export class AccessTokenInterceptor implements HttpInterceptor {
  constructor(private readonly authService: ExamdojoAuthService) {}

  /**
   * URLs which the access token should be applied to
   */
  readonly urlsWithAuth = [
    environment.examdojo.cmsApiUrl,
    environment.examdojo.examdojoApiUrl,
    environment.examdojo.questionEngagementApi,
    environment.examdojo.studentsApiUrl,
    environment.examdojo.billingApiUrl,
  ];

  private readonly urlsWithAuthRegExps: RegExp[] = this.urlsWithAuth
    .filter(Boolean)
    .map((url) => new RegExp(escapeRegExp(url)));

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.urlsWithAuthRegExps.some((url) => url.test(req.url))) {
      return next.handle(req);
    }

    return this.authService.selectAccessToken().pipe(
      map((token) => this.setAuthorizationHeader(req, token ?? '')),
      switchMap((request) => next.handle(request)),
    );
  }

  private setAuthorizationHeader(request: HttpRequest<unknown>, token: string): HttpRequest<unknown> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
