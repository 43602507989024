<div class="toast-body">
  <dojo-icon class="toast-type-icon" [icon]="icon" [size]="16" />
  <div class="toast-body-message">
    <span *ngIf="sanitizedTitle" class="toast-title" [innerHTML]="sanitizedTitle"></span>
    <span *ngIf="sanitizedDescription" class="toast-description" [innerHTML]="sanitizedDescription"></span>

    <div class="toast-markdown-container" *ngIf="toastMarkdown">
      <markdown [data]="toastMarkdown"></markdown>
    </div>
  </div>
  <button *ngIf="dismissible" mat-icon-button class="toast-close custom-button-icon-large" (click)="close()">
    <dojo-icon [icon]="'close'" [size]="16" />
  </button>
</div>

<div class="toast-actions">
  @for (action of actions; track action.id) {
    <button [color]="action.color" mat-flat-button (click)="close(action.id)">
      {{ action.label }}
    </button>
  }
</div>
