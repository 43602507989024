import { Component } from '@angular/core';
import { connectState } from '@examdojo/angular/util';
import { Icon, IconComponent } from '@examdojo/core/icon';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ColDef } from 'ag-grid-community';
import { map } from 'rxjs';
import { BaseCellRendererComponent, Params } from '../base-cell-renderer.component';

type IconCellRendererValue = Icon;
type IconCellRendererData = unknown;
type ParamsType = Params<IconCellRendererValue, IconCellRendererData>;

@UntilDestroy()
@Component({
  selector: 'dojo-icon-cell-renderer',
  templateUrl: './icon-cell-renderer.component.html',
  styleUrls: ['./icon-cell-renderer.component.scss'],
  standalone: true,
  imports: [IconComponent],
})
export class IconCellRendererComponent extends BaseCellRendererComponent<
  IconCellRendererValue,
  IconCellRendererData,
  ParamsType
> {
  readonly state = connectState({
    icon: this.params$.pipe(map((params) => params.value)),
  });
}

export const ICON_CELL_RENDERER_BASE_COL_DEF: ColDef = {
  headerName: '',
  width: 40,
  suppressAutoSize: true,
  suppressSizeToFit: true,
  resizable: false,
  sortable: false,
  suppressMenu: true,
  cellRenderer: IconCellRendererComponent,
};
