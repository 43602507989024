import { JsonPipe, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { BaseButton } from '@examdojo/core/button';
import { IonButton, IonSpinner } from '@ionic/angular/standalone';

export type ButtonExpand = IonButton['expand'];
export type ButtonFill = IonButton['fill'];

@Component({
  selector: 'dojo-button',
  standalone: true,
  imports: [IonButton, IonSpinner, JsonPipe, NgStyle],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
  host: {
    '[attr.disabled]': 'this.disabled() ? true : undefined',
    '[style.pointer-events]': "this.disabled() || this.pending ? 'none' : undefined",
    '[style.--custom-color-name]': 'this.customColor() || undefined',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: BaseButton, useExisting: ButtonComponent }],
})
export class ButtonComponent extends BaseButton {
  readonly expand = input<ButtonExpand>();
  readonly small = input<boolean>(false);
  readonly micro = input<boolean>(false);
  readonly fill = input<ButtonFill>('solid');

  // readonly color = input<ButtonColor>();
  readonly style = input<Record<string, string>>({});

  readonly customColor = input<string>();
  readonly customWidth = input<number>();
  readonly customHeight = input<number>();
}
