<div class="ag-cell-label-container" role="presentation">
  <span
    ref="eMenu"
    class="ag-header-icon ag-header-cell-menu-button"
    #menuButton
    (click)="showColumnMenu()"
    *ngIf="params.enableMenu"
  ></span>
  <div ref="eLabel" class="ag-header-cell-label" role="presentation" (click)="showSorted()">
    <div [matTooltip]="params.tooltip || ''" class="custom-header-cell-wrapper">
      <dojo-icon *ngIf="params.icon" class="ag-header-cell-icon mr-1" [icon]="params.icon" [size]="12" />
      <span ref="eText" class="ag-header-cell-text" role="columnheader">{{ params.displayName }}</span>
      <dojo-icon *ngIf="params.tooltip" class="ag-header-cell-icon ml-1" [icon]="'info-circle'" [size]="12" />
    </div>
    <span ref="eFilter" class="ag-header-icon ag-filter-icon" *ngIf="state.filterActive"></span>
    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>
    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" *ngIf="sorted === 'asc'"></span>
    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" *ngIf="sorted === 'desc'"></span>
    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>
  </div>
</div>
