import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { FeatureFlagService } from '@examdojo/core/feature-flag';
import { ExamdojoFeatureFlags } from '@examdojo/models/feature-flag';
import { IonApp } from '@ionic/angular/standalone';
import { register as registerSwiper } from 'swiper/element/bundle';
import { LanguageService } from './shared/i18n/language.service';
import { ShellComponent } from './shell/shell.component';
registerSwiper();

@Component({
  selector: 'exam-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [IonApp, RouterOutlet, ShellComponent],
})
export class AppComponent {
  constructor(private readonly languageService: LanguageService) {
    this.languageService.loadLanguageFromLocalStorage();
  }

  private readonly instantiatedServices = [FeatureFlagService<ExamdojoFeatureFlags>].map((service) => inject(service));
}
