import { Component } from '@angular/core';
import { connectState } from '@examdojo/angular/util';
import { TimeAgoModule } from '@examdojo/core/date-time';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BaseCellRendererComponent } from '../base-cell-renderer.component';

@UntilDestroy()
@Component({
  selector: 'dojo-time-ago-cell-renderer',
  standalone: true,
  imports: [TimeAgoModule],
  template: `<dojo-time-ago [date]="state.timeAgo"></dojo-time-ago>`,
})
export class TimeAgoCellRendererComponent extends BaseCellRendererComponent<Date> {
  public readonly state = connectState({
    timeAgo: this.valueAsSingle$,
  });
}
