<mat-button-toggle-group
  dojoStopPropagation
  class="custom-toggle-group"
  [value]="state.isOn"
  [disabled]="state.isDisabled"
  (change)="setState($event)"
  hideSingleSelectionIndicator
>
  <mat-button-toggle [value]="false">OFF</mat-button-toggle>
  <mat-button-toggle [value]="true">ON</mat-button-toggle>
</mat-button-toggle-group>
